import { useQuery } from '@tanstack/react-query';

import { useCurrentProjectKey } from 'context/current-project';
import { useAvailableProjectKeys } from 'hooks/use-available-project-keys';
import { useTimezone } from 'hooks/use-timezone';
import { REPORT_NAMES, UNKNOWN_COUNTRY } from 'utils/constants';
import {
  deserializeMetricsByDate,
  deserializeStatistic,
  deserializeTopEvent,
  deserializeTopMetrics,
  deserializeTopPlayer,
  deserializeTopTournament,
  deserializeBonusStatisticsGeneral,
  deserializeFreebetGeneralStatisticsByBonusId,
  deserializeFreebetGeneralStatisticsByBonusType,
  deserializeBonusStatisticsBooster,
} from 'utils/deserialize';
import {
  serializeDateFrom,
  serializeExcludedDateTo,
  serializeCustomerGroup,
} from 'utils/serialize';

import { apiClient } from './client-sportsbook-api';

export function useStatisticsReport({ params = {}, config = {} }) {
  const currentProjectKey = useCurrentProjectKey();
  const timezone = useTimezone();

  const {
    betTypes,
    categoryExternalIds,
    countries,
    currencies,
    devices,
    groupBy,
    groups,
    period,
    projectKey,
    projectKeys: paramsProjectKeys,
    reportName,
    sportExternalIds,
    sTags,
    status,
    timeGroupBy,
    tournamentExternalIds,
    bonusId,
  } = params;

  const { projectKeys } = useAvailableProjectKeys(paramsProjectKeys);

  return useQuery(
    ['report', params],
    () =>
      apiClient.axios
        .post(
          '/reports',
          {
            path: `sb/v1/reports/backoffice/${reportName}`,
            query: {
              timezone,
              from: serializeDateFrom(period?.[0], timezone),
              to: serializeExcludedDateTo(period?.[1], timezone),
              game_period: status,
              devices,
              date_group_by: groupBy,
              bet_types: betTypes,
              client_name: projectKey,
              client_names: projectKeys,
              sport_external_ids: sportExternalIds?.map(String),
              tournament_external_ids: tournamentExternalIds?.map(String),
              category_external_ids: categoryExternalIds?.map(String),
              countries: (countries ?? [])?.map(country =>
                country === UNKNOWN_COUNTRY ? '' : country
              ),
              customer_s_tags: sTags,
              currencies,
              customer_groups: groups?.map(serializeCustomerGroup).filter(Boolean),
              time_group_by: timeGroupBy,
              bonus_id: bonusId,
            },
          },
          { headers: { 'X-Project': currentProjectKey } }
        )
        .then(response => {
          switch (reportName) {
            case REPORT_NAMES.METRICS:
              return {
                data: response.data?.statistic?.map(deserializeStatistic),
                total: deserializeStatistic(response.data?.totals),
              };
            case REPORT_NAMES.METRICS_BY_SPORT:
            case REPORT_NAMES.METRICS_BY_DEVICE:
            case REPORT_NAMES.METRICS_BY_BET_TYPE:
            case REPORT_NAMES.METRICS_BY_COUNTRY:
            case REPORT_NAMES.METRICS_BY_CURRENCY:
            case REPORT_NAMES.METRICS_BY_GROUP:
            case REPORT_NAMES.METRICS_BY_STAG:
            case REPORT_NAMES.METRICS_BY_PROJECT:
              return response.data?.statistic?.map(deserializeStatistic);
            case REPORT_NAMES.CUMULATIVE_METRICS_BY_TIME:
              return {
                fullPeriodData: deserializeMetricsByDate(response.data.period),
              };
            case REPORT_NAMES.TOURNAMENTS:
              return deserializeTopMetrics(response.data, deserializeTopTournament);
            case REPORT_NAMES.PLAYERS:
              return deserializeTopMetrics(response.data, deserializeTopPlayer);
            case REPORT_NAMES.EVENTS:
              return deserializeTopMetrics(response.data, deserializeTopEvent);

            case REPORT_NAMES.BONUSES_GENERAL_STATISTICS:
              return {
                data: response.data?.general?.map(deserializeBonusStatisticsGeneral),
                total: deserializeBonusStatisticsGeneral(response.data?.total),
                average: deserializeBonusStatisticsGeneral(response.data?.average),
              };
            case REPORT_NAMES.FREEBET_BONUSES_GENERAL_STATISTICS:
              return {
                byBonusId: {
                  data: response.data?.by_bonus_id?.general?.map(
                    deserializeFreebetGeneralStatisticsByBonusId
                  ),
                  total: deserializeFreebetGeneralStatisticsByBonusId(
                    response.data?.by_bonus_id?.total
                  ),
                  average: deserializeFreebetGeneralStatisticsByBonusId(
                    response.data?.by_bonus_id?.average
                  ),
                },
                byBonusType: {
                  data: response.data?.by_bonus_type?.general?.map(
                    deserializeFreebetGeneralStatisticsByBonusType
                  ),
                  total: deserializeFreebetGeneralStatisticsByBonusType(
                    response.data?.by_bonus_type?.total
                  ),
                  average: deserializeFreebetGeneralStatisticsByBonusType(
                    response.data?.by_bonus_type?.average
                  ),
                },
              };
            case REPORT_NAMES.FREEBET_BONUSES_BOOSTER_STATISTICS:
              return {
                data: response.data?.general?.map(deserializeBonusStatisticsBooster),
                total: deserializeBonusStatisticsBooster(response.data?.total),
                average: deserializeBonusStatisticsBooster(response.data?.average),
              };
            default:
              return response.data;
          }
        }),
    {
      initialData: [],
      // set 1ms cache time for report data. Should prevent displaying old data
      cacheTime: 1,
      ...config,
      enabled:
        !!currentProjectKey && (projectKey || projectKeys?.length > 0) && config.enabled !== false,
    }
  );
}
