import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useCurrentProjectKey } from 'context/current-project';
import { useTimezone } from 'hooks/use-timezone';
import { REPORT_TYPES } from 'utils/constants';
import { deserializeReport, deserializePagination } from 'utils/deserialize';
import { createPaginatedDataHook, createQueryKeys } from 'utils/query';
import {
  serializeEventsFilters,
  serializerBetsFilters,
  serializeTurfsportBetsFilters,
} from 'utils/serialize';

import { apiClient } from './client-sportsbook-api';

const reportsQueryKeys = createQueryKeys('reports');

const MAP_REPORT_TYPE_TO_SERIALIZER = {
  [REPORT_TYPES.BETS]: serializerBetsFilters,
  [REPORT_TYPES.EVENTS]: serializeEventsFilters,
  [REPORT_TYPES.TURFSPORT_BETS]: serializeTurfsportBetsFilters,
};

export function useCreateReport({ config } = {}) {
  const projectKey = useCurrentProjectKey();
  const timezone = useTimezone();

  return useMutation(({ filters, columns, reportType }) => {
    const serializer = MAP_REPORT_TYPE_TO_SERIALIZER[reportType];

    if (!serializer) {
      throw new Error(`Unknown report type ${reportType}`);
    }

    return apiClient.makeRequest('/exports', {
      method: 'POST',
      headers: {
        'X-Project': projectKey,
      },
      data: {
        timezone,
        columns,
        filter: serializer(filters, timezone),
        type: reportType,
      },
    });
  }, config);
}

export const useReports = createPaginatedDataHook({
  useData: ({ params = {}, config = {} }) => {
    return useQuery(
      reportsQueryKeys.list(params),
      () =>
        apiClient.makeRequest('/exports', { params }).then(({ data, pagination }) => ({
          data: data.map(deserializeReport),
          pagination: deserializePagination(pagination),
        })),
      config
    );
  },
});

export function useDownloadReport({ config = {} } = {}) {
  return useMutation(
    ({ reportId }) => apiClient.makeRequest(`/exports/${reportId}/download`),
    config
  );
}

export function useReportRetry({ config } = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ reportId }) => apiClient.makeRequest(`/exports/${reportId}/redo`, { method: 'POST' }),
    {
      ...config,
      onSuccess: response => {
        config?.onSuccess();

        const newReport = deserializeReport(response);
        return queryClient.setQueriesData(reportsQueryKeys.lists(), reports => {
          if (reports) {
            return {
              ...reports,
              data: reports.data?.map(report => (report.id === newReport.id ? newReport : report)),
            };
          }

          return reports;
        });
      },
    }
  );
}
