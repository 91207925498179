import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { SORT_BY } from 'utils/constants';
import { deserializeRole } from 'utils/deserialize';
import { createQueryKeys } from 'utils/query';
import { serializeRole } from 'utils/serialize';

import { apiClient } from './client-sportsbook-api';

const rolesQueriesKeys = createQueryKeys('roles');

export function useRoles(config = {}) {
  return useQuery(
    rolesQueriesKeys.list(),
    () =>
      apiClient.axios
        .get('/roles', { params: { sort_by: SORT_BY.NAME.ASC } })
        .then(response => response.data.map(deserializeRole)),
    { initialData: [], ...config }
  );
}

export function useRole({ roleKey, config = {} }) {
  return useQuery(
    rolesQueriesKeys.detail(roleKey),
    () => apiClient.axios.get(`/roles/${roleKey}`).then(response => deserializeRole(response.data)),
    config
  );
}

export function useAddRole(config = {}) {
  const queryClient = useQueryClient();

  return useMutation(role => apiClient.axios.post('/roles', serializeRole(role)), {
    ...config,
    onSuccess: () => {
      config.onSuccess?.();

      return queryClient.invalidateQueries(rolesQueriesKeys.lists());
    },
  });
}

export function useUpdateRole({ roleKey, config = {} }) {
  const queryClient = useQueryClient();

  return useMutation(role => apiClient.axios.patch(`/roles/${roleKey}`, serializeRole(role)), {
    ...config,
    onSuccess: () => {
      config.onSuccess?.();

      return queryClient.invalidateQueries(rolesQueriesKeys.lists());
    },
  });
}

export function useDeleteRole({ roleKey, config = {} }) {
  const queryClient = useQueryClient();

  return useMutation(() => apiClient.axios.delete(`/roles/${roleKey}`), {
    ...config,
    onSuccess: () => {
      config.onSuccess?.();

      return queryClient.invalidateQueries(rolesQueriesKeys.lists());
    },
  });
}
