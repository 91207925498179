import { useQuery } from '@tanstack/react-query';
import castArray from 'lodash.castarray';

import { createQueryKeys } from 'utils/query';

import { apiClient } from './client-sportsbook-api';

const queryKeys = createQueryKeys('currency-converter');

export const useCurrencyConverter = ({ from, to, amount, isRound }, config = {}) => {
  return useQuery(
    queryKeys.list({ from, to, amount: Array.isArray(amount) ? amount.join(',') : amount }),
    ({ signal }) =>
      Promise.all(
        castArray(amount).map(value =>
          apiClient.makeRequest('/currency-convert', {
            signal,
            params: { from, amount: value, to, round_bonus_amount: isRound },
          })
        )
      ).catch(error => error && config.onError?.(error)),

    {
      ...config,
      enabled:
        Boolean(from && (Array.isArray(to) ? to.length : to) && amount) && (config.enabled ?? true),
    }
  );
};
