import Image from 'next/image';
import PropTypes from 'prop-types';
import { memo } from 'react';

import { LOGO_URL } from 'utils/constants';

export function LogoUI({ className, height, width }) {
  return (
    <Image alt="logo" className={className} height={height} src={LOGO_URL} width={width} priority />
  );
}

LogoUI.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

LogoUI.defaultProps = {
  className: undefined,
  height: 24,
  width: 158,
};

export const Logo = memo(LogoUI);
