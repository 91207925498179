import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { useUserProfile } from 'context/auth';
import { deserializeUser } from 'utils/deserialize';

import { apiClient } from './client-sportsbook-api';

export function useLogin(config = {}) {
  const { addProfile } = useUserProfile();

  return useMutation(
    payload =>
      apiClient.axios.post('/login', payload).then(response => deserializeUser(response.data.user)),
    {
      onSuccess: addProfile,
      ...config,
    }
  );
}

export function useLogout(config = {}) {
  const queryClient = useQueryClient();
  const { removeProfile } = useUserProfile();

  return useMutation(() => apiClient.axios.post('/logout'), {
    onSuccess: () => {
      queryClient.clear();
      removeProfile();
    },
    useErrorBoundary: true,
    ...config,
  });
}

export function useVerifyResetToken({ token, config = {} }) {
  return useQuery(
    ['reset-token', token],
    () =>
      apiClient.axios
        .get(`/password-reset/${encodeURIComponent(token)}`)
        .then(response => response.data?.totp_uri ?? null),
    config
  );
}

export function useSetPassword({ token, config = {} }) {
  return useMutation(
    ({ password, totp }) =>
      apiClient.axios({
        baseURL: apiClient.baseUrlApiV2,
        method: 'POST',
        url: `/password-reset/${encodeURIComponent(token)}`,
        data: {
          password,
          totp_passcode: totp,
        },
      }),
    config
  );
}

export function useResetPassword(config = {}) {
  return useMutation(payload => apiClient.axios.post('/password-reset', payload), config);
}
