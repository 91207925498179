import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useRef, useEffect } from 'react';

import { useCurrentProjectKey } from 'context/current-project';
import { createQueryKeys } from 'utils/query';

import { apiClient } from './client-sportsbook-api';

const fileQueryKeys = createQueryKeys('files');
const folderQueryKeys = createQueryKeys('folders');

export function useFolders({ params = {}, config = {} } = {}) {
  const projectKey = useCurrentProjectKey();

  return useQuery(
    folderQueryKeys.list({ ...params, projectKey }),
    () => apiClient.getFolders({ ...params, projectKey }),
    {
      ...config,
      enabled: Boolean(projectKey) && (config.enabled ?? true),
    }
  );
}

const selectFirstFolder = folders => folders?.[0];

export function useUploadFile({ params = {}, config = {} } = {}) {
  const queryClient = useQueryClient();
  const projectKey = useCurrentProjectKey();
  const abortControllerRef = useRef();

  const { folderName, onUploadProgress } = params;
  const { data: folder } = useFolders({
    params: { name: folderName },
    config: { select: selectFirstFolder },
  });

  const mutation = useMutation(
    file => {
      abortControllerRef.current = new AbortController();

      return apiClient.uploadFile(
        { projectKey, folderId: folder?.id, file },
        { onUploadProgress, signal: abortControllerRef.current.signal }
      );
    },
    {
      ...config,
      onSuccess: response => {
        config.onSuccess?.(response);

        return queryClient.invalidateQueries(
          fileQueryKeys.list({ projectKey, folderId: folder?.id })
        );
      },
    }
  );

  return { mutation, abortControllerRef };
}

export function useFiles({ params = {}, config = {} } = {}) {
  const projectKey = useCurrentProjectKey();

  const { folderName, ...restParams } = params;

  const { data: folder } = useFolders({
    params: { name: folderName },
    config: { select: selectFirstFolder },
  });

  return useQuery(
    fileQueryKeys.list({ ...restParams, folderId: folder?.id, projectKey }),
    () => apiClient.getFiles({ ...restParams, folderId: folder?.id, projectKey }),

    {
      ...config,
      enabled: Boolean(folder) && Boolean(projectKey) && (config.enabled ?? true),
    }
  );
}

function useCreateFolder(config = {}) {
  const queryClient = useQueryClient();
  const projectKey = useCurrentProjectKey();

  return useMutation(payload => apiClient.createFolder({ projectKey, payload }), {
    ...config,
    onSuccess: () => {
      config.onSuccess?.();

      return queryClient.invalidateQueries(folderQueryKeys.list({ projectKey }));
    },
  });
}

export function useSyncFolderExists({ params = {}, config = {} } = {}) {
  const { folderName } = params;

  const { mutate: createFolder } = useCreateFolder();
  const { data: folder, isFetchedAfterMount } = useFolders({
    params: { name: folderName },
    config: { select: selectFirstFolder, enabled: Boolean(folderName) && (config.enabled ?? true) },
  });

  useEffect(() => {
    if (isFetchedAfterMount && !folder) {
      createFolder({ name: folderName });
    }
  }, [createFolder, folder, folderName, isFetchedAfterMount]);
}
