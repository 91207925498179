import PropTypes from 'prop-types';
import { createContext, memo, useContext, useMemo } from 'react';

import { useProjectsSettings } from 'api/project';

export const ProjectsProvidersContext = createContext();

function ProjectsProvidersProviderUI({ projectKeys, children }) {
  const validProjectKeys = projectKeys.filter(Boolean);

  const projectsSettingsData = useProjectsSettings({
    projectKeys: validProjectKeys,
    config: {
      useErrorBoundary: false,
      enabled: validProjectKeys.length > 0,
    },
  });

  const uniqueProjectsProviders = useMemo(
    () => [
      ...new Set(
        projectsSettingsData.flatMap(projectSettingsData =>
          Object.values(projectSettingsData?.data?.sportTypeByProvider ?? {})
        )
      ),
    ],
    [projectsSettingsData]
  );

  return (
    <ProjectsProvidersContext.Provider value={uniqueProjectsProviders}>
      {children}
    </ProjectsProvidersContext.Provider>
  );
}

ProjectsProvidersProviderUI.propTypes = {
  projectKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
};

export function useProjectsProviders() {
  const providers = useContext(ProjectsProvidersContext);

  if (providers === undefined) {
    throw new Error(`useProjectsProviders must be used within a ProjectsProvidersContext.`);
  }

  return providers;
}

export const ProjectsProvidersProvider = memo(ProjectsProvidersProviderUI);
