import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useCurrentProjectKey } from 'context/current-project';
import { useTimezone } from 'hooks/use-timezone';
import { replaceElementById } from 'utils/array';
import { DATA_PROVIDERS } from 'utils/constants';
import {
  deserializeSportsbookBet,
  deserializeTurfsportBet,
  deserializeBetSelection,
  deserializePagination,
} from 'utils/deserialize';
import { createPaginatedDataHook, createQueryKeys } from 'utils/query';
import { serializerBetsFilters, serializeTurfsportBetsFilters } from 'utils/serialize';

import { apiClient } from './client-sportsbook-api';

export const betsQueryKeys = createQueryKeys('bets');

const MAP_BETS_PROVIDER = {
  [DATA_PROVIDERS.SPORTSBOOK]: {
    path: '/bets',
    serializeFilters: serializerBetsFilters,
    deserializeBet: deserializeSportsbookBet,
  },
  [DATA_PROVIDERS.TURFSPORT]: {
    path: '/virtual-wallet/bets',
    serializeFilters: serializeTurfsportBetsFilters,
    deserializeBet: deserializeTurfsportBet,
  },
};

export const useBets = createPaginatedDataHook({
  useData: ({ params = {}, config = {} }) => {
    const projectKey = useCurrentProjectKey();
    const timezone = useTimezone();

    const { path, serializeFilters, deserializeBet } = MAP_BETS_PROVIDER[params.provider];

    return useQuery(
      betsQueryKeys.list({ ...params, projectKey }),
      () =>
        apiClient.axios
          .get(path, {
            params: serializeFilters(params, timezone),
            headers: { 'X-Project': projectKey },
          })
          .then(response => response.data)
          .then(({ data, pagination }) => ({
            data: data.map(deserializeBet),
            pagination: deserializePagination(pagination),
          })),
      {
        ...config,
        enabled: Boolean(projectKey) && (config.enabled ?? true),
      }
    );
  },
});

export const useApproveBet = (config = {}) => {
  const queryClient = useQueryClient();
  const projectKey = useCurrentProjectKey();

  return useMutation(
    ({ betId }) =>
      apiClient.axios.patch(`/bets/${betId}/accept`, null, {
        headers: {
          'X-Project': projectKey,
        },
      }),
    {
      ...config,
      onSuccess: () => {
        config.onSuccess?.();

        return queryClient.invalidateQueries(betsQueryKeys.list({ projectKey }));
      },
    }
  );
};

export const useRejectBet = (config = {}) => {
  const queryClient = useQueryClient();
  const projectKey = useCurrentProjectKey();

  return useMutation(
    ({ betId }) =>
      apiClient.axios.patch(`/bets/${betId}/reject`, null, {
        headers: {
          'X-Project': projectKey,
        },
      }),
    {
      ...config,
      onSuccess: () => {
        config.onSuccess?.();

        return queryClient.invalidateQueries(betsQueryKeys.list({ projectKey }));
      },
    }
  );
};

export const useProcessBetManually = (config = {}) => {
  const queryClient = useQueryClient();
  const projectKey = useCurrentProjectKey();

  return useMutation(
    ({ betId }) =>
      apiClient.axios
        .patch(`/bets/${betId}/enable-manual-processing `, null, {
          headers: {
            'X-Project': projectKey,
          },
        })
        .catch(error => {
          // Unexpected behavior for react-query.
          // It doesn't catch axios errors for query after this PATCH request
          throw new Error(error.response.data.message);
        }),
    {
      ...config,
      onSuccess: response => {
        const updatedBet = deserializeSportsbookBet(response.data);

        queryClient.setQueriesData(betsQueryKeys.list({ projectKey }), oldData => {
          if (oldData) {
            return {
              ...oldData,
              data: replaceElementById(oldData.data, updatedBet),
            };
          }

          return oldData;
        });

        config.onSuccess?.();
      },
    }
  );
};

export const useRecalculateBet = (config = {}) => {
  const queryClient = useQueryClient();
  const projectKey = useCurrentProjectKey();

  return useMutation(
    ({ betId }) =>
      apiClient.axios.patch(`/bets/${betId}/recalculate `, null, {
        headers: {
          'X-Project': projectKey,
        },
      }),
    {
      ...config,
      onSuccess: response => {
        config.onSuccess?.();

        const updatedBet = deserializeSportsbookBet(response.data);

        queryClient.setQueriesData(betsQueryKeys.list({ projectKey }), oldData => {
          if (oldData) {
            return {
              ...oldData,
              data: replaceElementById(oldData.data, updatedBet),
            };
          }

          return oldData;
        });
      },
    }
  );
};

export const useUpdateBetSelectionStatus = (config = {}) => {
  const queryClient = useQueryClient();
  const projectKey = useCurrentProjectKey();

  return useMutation(
    ({ betId, selectionId, status }) =>
      apiClient.axios.patch(
        `/bets/${betId}/selections/${selectionId}`,
        { status },
        {
          headers: {
            'X-Project': projectKey,
          },
        }
      ),
    {
      ...config,
      onSuccess: response => {
        const updatedSelection = deserializeBetSelection(response.data);

        // Now, because of the MUI bug there is no other way to update the data
        // without collapsing the whole table. If "rows" object is new, the table loses its state
        // https://github.com/mui/mui-x/issues/9101
        // https://github.com/mui/mui-x/issues/7771
        // https://github.com/mui/mui-x/issues/8676
        // That is why we return what we need to update and update this row in the table manually.
        // Changing rows={rows} to updateRows as general solution for everything didn't help because
        // of the concurrency issues.
        queryClient.setQueriesData(betsQueryKeys.list({ projectKey }), oldData => {
          if (oldData) {
            const parent = oldData.data.find(item => {
              const sectionIds = item.selections.map(selection => selection.id);
              return sectionIds.includes(updatedSelection.id);
            });

            if (!parent) {
              config.onSuccess?.();
              return oldData;
            }

            const updatedParent = {
              ...parent,
              selections: replaceElementById(parent.selections, updatedSelection),
            };

            config.onSuccess?.(updatedParent);

            return oldData;
          }

          config.onSuccess?.();

          return oldData;
        });
      },
    }
  );
};
