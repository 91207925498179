import axios from 'axios';
import Cookies from 'js-cookie';
import queryString from 'query-string';

import { BONUS_TYPES, COOKIES, SORT_BY, USER_STATUSES } from 'utils/constants';
import {
  deserializePagination,
  deserializeProject,
  deserializeProjectSettings,
  deserializeUser,
  deserializeMergedPlayersList,
  deserializeMergedPlayer,
  deserializePlayer,
  deserializeFiles,
  deserializeCmsPagination,
  deserializeFolder,
} from 'utils/deserialize';
import {
  serializeUser,
  serializeProjectSettings,
  serializeMergePlayers,
  serializeDetachPlayers,
} from 'utils/serialize';

export const MAP_PLAYER_BONUS_TYPE_TO_API_KEY = {
  [BONUS_TYPES.COMBOBOOST]: 'comboboosts',
  [BONUS_TYPES.FREEBET]: 'freebets',
  [BONUS_TYPES.HUNTING]: 'huntings',
  [BONUS_TYPES.LOOTBOX]: 'lootboxes',
  [BONUS_TYPES.TOURNAMENT]: 'tournaments',
};

const getCmsUrl = projectKey => {
  if (process.env.NODE_ENV === 'development') {
    return process.env.CMS_API_ORIGIN;
  }
  // as replay has no cms, we use dev cms for it
  return `/${projectKey === 'sportsbook_replay' ? 'sportsbook_dev' : projectKey}/cms`;
};

class SportsbookApi {
  constructor({ sportsbookBackofficeApiUrl }) {
    this.axios = axios.create({
      baseURL: sportsbookBackofficeApiUrl,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
      paramsSerializer: params => queryString.stringify(params),
    });

    this.axios.interceptors.request.use(config => {
      const csrfToken = Cookies.get(COOKIES.CSRF_TOKEN);

      if (csrfToken) config.headers['x-csrf-token'] = csrfToken;

      return config;
    });

    this.baseUrlApiV2 = sportsbookBackofficeApiUrl.replace('/v1', '/v2');
  }

  makeRequest = (...args) => {
    return this.axios(...args)
      .then(response => response.data)
      .catch(error => {
        if (axios.isCancel(error)) {
          return Promise.reject();
        }

        throw error;
      });
  };

  getProject = projectKey =>
    this.makeRequest(`/projects/${projectKey}`).then(response => deserializeProject(response));

  getProjectSettings = projectKey =>
    this.makeRequest(`/projects/${projectKey}/settings`, {
      headers: { 'X-Project': projectKey },
    }).then(response => deserializeProjectSettings(response));

  getProjects = ({ page = 1, limit = 25, sortBy }) =>
    this.makeRequest('/projects', {
      params: {
        sort_by: sortBy?.length ? sortBy : SORT_BY.NAME.ASC,
        page,
        limit,
      },
    }).then(({ data, pagination }) => ({
      data: data.map(deserializeProject),
      pagination: deserializePagination(pagination),
    }));

  updateProject = ({ projectKey, payload: { name, feeGroups, managers } }) => {
    const payloadToSend = {
      name: name.trim(),
      fee_groups: feeGroups
        .split(',')
        .map(group => group.trim())
        .join(','),
      managers,
    };

    return this.axios
      .patch(`/projects/${projectKey}`, payloadToSend)
      .then(response => deserializeProject(response.data));
  };

  updateProjectSettings = ({ projectKey, payload }) =>
    this.axios({
      baseURL: this.baseUrlApiV2,
      method: 'PATCH',
      url: `/projects/${projectKey}/settings`,
      data: serializeProjectSettings(payload),
    }).then(response => deserializeProjectSettings(response.data));

  getPlayersHubProfiles = params => {
    return this.makeRequest('/players-hub/players', { params }).then(({ data, pagination }) => ({
      data: deserializeMergedPlayersList(data),
      pagination: deserializePagination(pagination),
    }));
  };

  getMergedPlayer = id => {
    return this.makeRequest(`/players-hub/players/${id}`, { params: { balance: true } }).then(
      deserializeMergedPlayer
    );
  };

  searchPlayersHubProfiles = params => {
    return this.makeRequest('/players-hub/players/search', { params }).then(
      ({ data, pagination }) => ({
        data: deserializeMergedPlayersList(data),
        pagination: deserializePagination(pagination),
      })
    );
  };

  mergePlayers = ({ mergeTo, playerIds }) =>
    this.axios
      .post('/players-hub/players/merge', serializeMergePlayers({ mergeTo, playerIds }))
      .then(response => deserializeMergedPlayer(response.data));

  detachProfile = ({ primaryPlayerId, secondaryPlayerId }) =>
    this.axios
      .post(
        `/players-hub/players/detach`,
        serializeDetachPlayers({ primaryPlayerId, secondaryPlayerId })
      )
      .then(response => deserializeMergedPlayer(response.data));

  changePrimaryProfile = ({ from, to }) =>
    this.axios
      .post('/players-hub/players/change-primary', { from, to })
      .then(response => deserializeMergedPlayer(response.data));

  getPlayers = ({
    page = 1,
    limit = 25,
    sortBy = SORT_BY.REGISTERED_AT.DESC,
    searchQuery,
    projectKey,
  }) => {
    return this.makeRequest('/players', {
      params: { page, limit, sort_by: sortBy, term: searchQuery },
      headers: {
        'X-Project': projectKey,
      },
    }).then(({ data, pagination }) => ({
      data: data.map(player => deserializePlayer(player)),
      pagination: deserializePagination(pagination),
    }));
  };

  getPlayer = ({ id, projectKey }) => {
    return this.makeRequest(`/players/${id}`, {
      baseURL: this.baseUrlApiV2,
      headers: {
        'X-Project': projectKey,
      },
      params: {
        balance: true,
      },
    }).then(data => deserializePlayer(data));
  };

  getUsers = ({
    page = 1,
    limit = 25,
    sortBy = [SORT_BY.NAME.ASC, SORT_BY.DEPARTMENT.ASC],
    projectKey,
    status,
  }) =>
    this.makeRequest(`/projects/${projectKey}/users`, {
      params: {
        sort_by: sortBy,
        page,
        limit,
        status: status || Object.values(USER_STATUSES),
      },
    }).then(({ data, pagination }) => ({
      data: data.map(deserializeUser),
      pagination: deserializePagination(pagination),
    }));

  blockUser = ({ projectKey, userId }) =>
    this.axios.post(`/projects/${projectKey}/users/${userId}/block`);

  unblockUser = ({ projectKey, userId, payload }) =>
    this.axios.post(`/projects/${projectKey}/users/${userId}/unblock`, serializeUser(payload));

  unfreezeUser = ({ projectKey, userId, withTotpReset }) =>
    this.axios.post(`/projects/${projectKey}/users/${userId}/unfreeze`, null, {
      params: {
        totp_reset: withTotpReset,
      },
    });

  addUser = ({ projectKey, payload }) =>
    this.axios.post(`/projects/${projectKey}/users`, serializeUser(payload));

  updateUser = ({ projectKey, userId, payload }) =>
    this.axios.patch(`/projects/${projectKey}/users/${userId}`, serializeUser(payload));

  getDictionary = ({ dictionaryKey, deserialize, projectKey, params, data, ...restConfig }) => {
    const request = this.makeRequest({
      method: data ? 'POST' : 'GET',
      url: `/dictionary/${dictionaryKey}`,
      params,
      data,
      headers: { 'X-Project': projectKey },
      ...restConfig,
    });

    return request.then(res => (deserialize ? deserialize(res) : res));
  };

  getTerms = ({ language, bonusType, projectKey }) => {
    return this.axios({
      baseURL: this.baseUrlApiV2,
      method: 'GET',
      url: `/bonuses/terms/${bonusType}/${language}/template`,
      headers: { 'X-Project': projectKey },
    }).then(response => response.data);
  };

  updateTerms = ({ projectKey, bonusTypeName, language, terms }) => {
    return this.axios({
      baseURL: this.baseUrlApiV2,
      method: 'PUT',
      url: `/bonuses/terms/${bonusTypeName}/${language}/template`,
      data: terms,
      headers: { 'X-Project': projectKey },
    });
  };

  getFolders = ({ projectKey, name }) =>
    this.axios({
      baseURL: getCmsUrl(projectKey),
      method: 'GET',
      url: '/upload/folders',
      params: { 'filters[$and][0][name]': name },
    }).then(response => response.data.data?.map(deserializeFolder));

  createFolder = ({ projectKey, payload }) =>
    this.axios({
      baseURL: getCmsUrl(projectKey),
      method: 'POST',
      url: '/upload/folders',
      data: payload,
    }).then(response => deserializeFolder(response.data.data));

  uploadFile = ({ projectKey, folderId, file }, axiosConfig) => {
    const data = new FormData();

    data.append('files', file);
    data.set(
      'fileInfo',
      JSON.stringify({
        name: file.name,
        caption: file.name,
        alternativeText: file.name,
        folder: folderId,
      })
    );

    return this.axios({
      baseURL: getCmsUrl(projectKey),
      method: 'POST',
      url: '/upload',
      data,
      ...axiosConfig,
    })
      .then(response => deserializeFiles(response?.data))
      .catch(error => {
        if (axios.isCancel(error)) return;
        throw error;
      });
  };

  getFiles = ({ projectKey, folderId }) => {
    return this.axios({
      baseURL: getCmsUrl(projectKey),
      method: 'GET',
      url: '/upload/files',
      params: {
        sort: 'updatedAt:DESC',
        page: 1,
        pageSize: 100,
        'filters[$and][0][folder][id]': folderId,
      },
    }).then(response => ({
      data: deserializeFiles(response.data.results),
      pagination: deserializeCmsPagination(response.data.pagination),
    }));
  };
}

export const apiClient = new SportsbookApi({
  sportsbookBackofficeApiUrl: `${process.env.API_ORIGIN}${process.env.API_PATH}`,
});
