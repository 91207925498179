import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { useCurrentProjectKey } from 'context/current-project';
import { SORT_BY } from 'utils/constants';
import { deserializeDraft, deserializePagination } from 'utils/deserialize';
import { createQueryKeys, createPaginatedDataHook } from 'utils/query';
import { serializeDraft } from 'utils/serialize';

import { apiClient } from './client-sportsbook-api';

const draftsQueriesKeys = createQueryKeys('drafts');

export const useDrafts = createPaginatedDataHook({
  useData: ({ params = {}, config = {} }) => {
    const projectKey = useCurrentProjectKey();
    const { page = 1, limit = 25, sortBy = [SORT_BY.CREATED_AT.ASC], type, bonusId } = params;

    return useQuery(
      draftsQueriesKeys.list({ projectKey, ...params }),
      () =>
        apiClient.axios
          .get('/bonuses/drafts', {
            params: {
              limit,
              page,
              sort_by: sortBy,
              type,
              ...(bonusId ? { bonus_id: bonusId } : {}),
            },
            headers: {
              'X-Project': projectKey,
            },
          })
          .then(response => response.data)
          .then(({ data, pagination }) => ({
            data: data.map(deserializeDraft),
            pagination: deserializePagination(pagination),
          })),
      {
        ...config,
        enabled: Boolean(projectKey) && (config.enabled ?? true),
      }
    );
  },
});

export const getDraft = async ({ projectKey, draftId }, config) => {
  return apiClient.axios
    .get(`/bonuses/drafts/${draftId}`, {
      ...config,
      headers: { ...config?.headers, 'X-Project': projectKey },
    })
    .then(response => deserializeDraft(response.data));
};

export function useDraft({ draftId, config = {} }) {
  const projectKey = useCurrentProjectKey();

  return useQuery(draftsQueriesKeys.detail(draftId), () => getDraft({ projectKey, draftId }), {
    ...config,
    enabled: Boolean(projectKey) && (config.enabled ?? true),
  });
}

export const useUpsertDraft = ({ bonusType, draftId, config = {} }) => {
  const queryClient = useQueryClient();
  const projectKey = useCurrentProjectKey();

  return useMutation(
    draft =>
      (!draftId
        ? apiClient.axios.post(`/bonuses/drafts`, serializeDraft(draft, bonusType), {
            headers: { 'X-Project': projectKey },
          })
        : apiClient.axios.patch(`/bonuses/drafts/${draftId}`, serializeDraft(draft, bonusType), {
            headers: { 'X-Project': projectKey },
          })
      ).then(response => deserializeDraft(response.data)),
    {
      ...config,
      onSuccess: draft => {
        config.onSuccess?.(draft);

        return queryClient.invalidateQueries(draftsQueriesKeys.lists());
      },
    }
  );
};

export function useDeleteDraft({ draftId, config = {} }) {
  const queryClient = useQueryClient();
  const projectKey = useCurrentProjectKey();

  return useMutation(
    () =>
      apiClient.axios.delete(`/bonuses/drafts/${draftId}`, {
        headers: {
          'X-Project': projectKey,
        },
      }),
    {
      ...config,
      onSuccess: () => {
        config.onSuccess?.();
        return queryClient.invalidateQueries(draftsQueriesKeys.lists());
      },
      onError: () => {
        config?.onError();
        return queryClient.invalidateQueries(draftsQueriesKeys.lists());
      },
    }
  );
}
