import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { createQueryKeys, createPaginatedDataHook } from 'utils/query';

import { apiClient } from './client-sportsbook-api';

export const userQueriesKeys = createQueryKeys('users');

export const useUsers = createPaginatedDataHook({
  useData: ({ params = {}, config = {} }) =>
    useQuery(userQueriesKeys.list(params), () => apiClient.getUsers(params), {
      ...config,
      enabled: Boolean(params.projectKey) && (config.enabled ?? true),
    }),
});

export const useBlockUser = ({ projectKey, userId, config = {} }) => {
  const queryClient = useQueryClient();

  return useMutation(() => apiClient.blockUser({ projectKey, userId }), {
    ...config,
    onSuccess: () => {
      config.onSuccess?.();

      return Promise.all([
        queryClient.invalidateQueries(userQueriesKeys.detail(userId)),
        queryClient.invalidateQueries(userQueriesKeys.lists()),
      ]);
    },
  });
};

export const useUnblockUser = ({ projectKey, userId, config = {} }) => {
  const queryClient = useQueryClient();

  return useMutation(payload => apiClient.unblockUser({ projectKey, userId, payload }), {
    ...config,
    onSuccess: () => {
      config.onSuccess?.();

      return Promise.all([
        queryClient.invalidateQueries(userQueriesKeys.detail(userId)),
        queryClient.invalidateQueries(userQueriesKeys.lists()),
      ]);
    },
  });
};

export const useUnfreezeUser = ({ projectKey, userId, withTotpReset, config = {} }) => {
  const queryClient = useQueryClient();

  return useMutation(() => apiClient.unfreezeUser({ projectKey, userId, withTotpReset }), {
    ...config,
    onSuccess: () => {
      config.onSuccess?.();

      return Promise.all([
        queryClient.invalidateQueries(userQueriesKeys.detail(userId)),
        queryClient.invalidateQueries(userQueriesKeys.lists()),
      ]);
    },
  });
};

export const useAddUser = ({ projectKey, config = {} }) => {
  const queryClient = useQueryClient();

  return useMutation(payload => apiClient.addUser({ projectKey, payload }), {
    ...config,
    onSuccess: () => {
      config.onSuccess?.();

      return queryClient.invalidateQueries(userQueriesKeys.lists());
    },
  });
};

export const useUpdateUser = ({ projectKey, userId, config = {} }) => {
  const queryClient = useQueryClient();

  return useMutation(payload => apiClient.updateUser({ projectKey, userId, payload }), {
    ...config,
    onSuccess: () => {
      config.onSuccess?.();

      return queryClient.invalidateQueries(userQueriesKeys.lists());
    },
  });
};

export const useReactivateUser = ({ projectKey, userId, config = {} }) => {
  const queryClient = useQueryClient();

  return useMutation(
    () => apiClient.axios.post(`/projects/${projectKey}/users/${userId}/activation/send`),
    {
      ...config,
      onSuccess: () => {
        config.onSuccess?.();

        return Promise.all([
          queryClient.invalidateQueries(userQueriesKeys.detail(userId)),
          queryClient.invalidateQueries(userQueriesKeys.lists()),
        ]);
      },
    }
  );
};
