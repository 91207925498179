import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useTimezone } from 'hooks/use-timezone';
import { PROFILES_MERGED_BY, SORT_BY } from 'utils/constants';
import {
  deserializePagination,
  deserializeSportsbookBet,
  deserializePlayerBonus,
  deserializeCcfHistoryItem,
  deserializeLtdHistoryItem,
  deserializePlayerStatistics,
} from 'utils/deserialize';
import { createPaginatedDataHook, createQueryKeys, invalidateProfiles } from 'utils/query';
import {
  serializeDateFrom,
  serializeDateTo,
  serializeCcf,
  serializeLtd,
  serializeBetsStatisticsParams,
} from 'utils/serialize';

import { MAP_PLAYER_BONUS_TYPE_TO_API_KEY, apiClient } from './client';

export const playerHubQueriesKeys = createQueryKeys('players-hub');

export const usePlayersHubProfiles = createPaginatedDataHook({
  useData: ({ params = {}, config = {} }) => {
    const timezone = useTimezone();

    const method = params?.searchQuery ? 'searchPlayersHubProfiles' : 'getPlayersHubProfiles';
    const {
      page = 1,
      limit = 25,
      sortBy = SORT_BY.REGISTERED_AT,
      searchQuery,
      isMerged,
      mergedPeriod,
      mergedBy,
      mergedTerm,
    } = params;

    return useQuery(
      playerHubQueriesKeys.list(params),
      () =>
        apiClient[method]({
          page,
          limit,
          sort_by: sortBy,
          email: searchQuery,
          merged: isMerged || undefined,
          merged_at_from: serializeDateFrom(mergedPeriod?.[0], timezone),
          merged_at_to: serializeDateTo(mergedPeriod?.[1], timezone),
          merged_by_type: mergedBy || undefined,
          merged_by_emails:
            mergedBy === PROFILES_MERGED_BY.AUTO || !mergedTerm ? undefined : [mergedTerm],
        }),
      config
    );
  },
});

export const useMergePlayers = (config = {}) => {
  const queryClient = useQueryClient();

  return useMutation(({ mergeTo, playerIds }) => apiClient.mergePlayers({ mergeTo, playerIds }), {
    ...config,
    onSuccess: newMergedPlayerProfiles => {
      config.onSuccess?.(newMergedPlayerProfiles);

      return queryClient.invalidateQueries(playerHubQueriesKeys.lists());
    },
  });
};

export const useDetachPlayers = (config = {}) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ primaryPlayerId, secondaryPlayerId }) =>
      apiClient.detachProfile({ primaryPlayerId, secondaryPlayerId }),
    {
      ...config,
      onSuccess: detachedPlayer => {
        config.onSuccess?.(detachedPlayer);

        return queryClient.invalidateQueries(playerHubQueriesKeys.lists());
      },
    }
  );
};

export const useChangePrimaryProfile = (config = {}) => {
  const queryClient = useQueryClient();

  return useMutation(({ from, to }) => apiClient.changePrimaryProfile({ from, to }), {
    ...config,
    onSuccess: newMergedPlayerProfiles => {
      config.onSuccess?.(newMergedPlayerProfiles);

      return queryClient.invalidateQueries(playerHubQueriesKeys.lists());
    },
  });
};

export const useMergedPlayer = (id, config = {}) =>
  useQuery(playerHubQueriesKeys.detail(id), () => apiClient.getMergedPlayer(id), config);

export const usePlayersMetrics = ({ params = {}, config = {} } = {}) => {
  const timezone = useTimezone();

  return useQuery(
    playerHubQueriesKeys.path('metrics', params),
    () =>
      apiClient
        .makeRequest('/players-hub/players/metrics', {
          baseURL: apiClient.baseUrlApiV2,
          method: 'POST',
          data: serializeBetsStatisticsParams({ params, timezone }),
        })
        .then(response => response.total)
        .then(deserializePlayerStatistics),
    config
  );
};

export const useMergedPlayerBonuses = createPaginatedDataHook({
  useData: ({ params = {}, config = {} }) => {
    const timezone = useTimezone();
    const { playerId, ...restParams } = params;
    const {
      projectKey,
      bonusType,
      issuedPeriod,
      limit = 25,
      page = 1,
      status,
      term,
      sortBy,
    } = restParams;

    return useQuery(
      playerHubQueriesKeys.path(playerId, 'bonuses', restParams),
      () =>
        apiClient
          .makeRequest(
            `/players-hub/${projectKey}/players/${playerId}/${MAP_PLAYER_BONUS_TYPE_TO_API_KEY[bonusType]}`,
            {
              params: {
                issued_from: serializeDateFrom(issuedPeriod?.[0], timezone),
                issued_to: serializeDateTo(issuedPeriod?.[1], timezone),
                limit,
                page,
                status,
                term,
                sort_by: sortBy,
              },
            }
          )
          .then(({ data, pagination }) => ({
            data: data.map(bonus => deserializePlayerBonus(bonusType, bonus)),
            pagination: deserializePagination(pagination),
          })),
      {
        ...config,
        enabled: Boolean(projectKey) && (config.enabled ?? true),
      }
    );
  },
});

export const useMergedPlayerBets = createPaginatedDataHook({
  useData: ({ params = {}, config = {} }) => {
    const { playerId, limit, sortBy } = params;

    return useQuery(
      playerHubQueriesKeys.path(playerId, 'bets', params),
      () =>
        apiClient
          .makeRequest(`/players-hub/players/${playerId}/bets`, {
            params: {
              limit,
              sort_by: sortBy,
            },
            headers: { 'X-Project': params.projectKey },
          })
          .then(({ data }) => ({ data: data.map(deserializeSportsbookBet) })),
      {
        ...config,
        enabled: Boolean(params.projectKey) && (config.enabled ?? true),
      }
    );
  },
});

export const useMergedPlayerEditCcf = ({ params = {}, config } = {}) => {
  const queryClient = useQueryClient();

  const { projectKey, profiles } = params;

  return useMutation(
    payload => {
      return apiClient.makeRequest('/players-hub/ccf', {
        baseURL: apiClient.baseUrlApiV2,
        method: 'PATCH',
        headers: {
          'X-Project': projectKey,
        },
        data: { ccfs: serializeCcf({ payload, profiles }) },
      });
    },
    {
      ...config,
      onSuccess: () => {
        config.onSuccess?.();

        return invalidateProfiles({
          historyPath: 'ccf-history',
          keys: playerHubQueriesKeys,
          profiles,
          queryClient,
        });
      },
    }
  );
};

export const useMergedPlayerEditLtd = ({ params = {}, config } = {}) => {
  const queryClient = useQueryClient();

  const { projectKey, profiles } = params;

  return useMutation(
    payload =>
      apiClient.makeRequest('/players-hub/ltd', {
        baseURL: apiClient.baseUrlApiV2,
        method: 'PATCH',
        headers: {
          'X-Project': projectKey,
        },
        data: { ltds: serializeLtd({ payload, profiles }) },
      }),
    {
      ...config,
      onSuccess: () => {
        config.onSuccess?.();

        return invalidateProfiles({
          historyPath: 'ltd-history',
          keys: playerHubQueriesKeys,
          profiles,
          queryClient,
        });
      },
    }
  );
};

export const useMergedPlayerCcfHistory = ({ params = {}, config = {} } = {}) => {
  const { globalId, projectKey } = params;

  return useQuery(
    playerHubQueriesKeys.path('ccf-history', params),
    () =>
      apiClient
        .makeRequest('/players-hub/ccf/history', {
          params: { global_id: globalId },
          headers: { 'X-Project': projectKey },
        })
        .then(response => response.data.map(deserializeCcfHistoryItem)),
    {
      ...config,
      enabled: Boolean(projectKey) && (config.enabled ?? true),
    }
  );
};

export const useMergedPlayerLtdHistory = ({ params = {}, config = {} } = {}) => {
  const { projectKey, globalId } = params;

  return useQuery(
    playerHubQueriesKeys.path('ltd-history', params),
    () =>
      apiClient
        .makeRequest('/players-hub/ltd/history', {
          params: { global_id: globalId },
          headers: { 'X-Project': projectKey },
        })
        .then(response => response.data.map(deserializeLtdHistoryItem)),
    {
      ...config,
      enabled: Boolean(projectKey) && (config.enabled ?? true),
    }
  );
};
