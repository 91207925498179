import { useQuery } from '@tanstack/react-query';

import { useCurrentProjectKey } from 'context/current-project';
import { useTimezone } from 'hooks/use-timezone';
import { deserializeEvent, deserializePagination } from 'utils/deserialize';
import { createPaginatedDataHook, createQueryKeys } from 'utils/query';
import { serializeEventsFilters } from 'utils/serialize';

import { apiClient } from './client-sportsbook-api';

export const eventsQueryKeys = createQueryKeys('events');

export const useEvents = createPaginatedDataHook({
  useData: ({ params = {}, config = {} }) => {
    const projectKey = useCurrentProjectKey();
    const timezone = useTimezone();

    return useQuery(
      eventsQueryKeys.list({ ...params, projectKey }),
      () =>
        apiClient.axios
          .get('/report/events', {
            params: serializeEventsFilters(params, timezone),
            headers: {
              'X-Project': projectKey,
            },
          })
          .then(response => response.data)
          .then(({ data, pagination }) => ({
            data: data.map(deserializeEvent),
            pagination: deserializePagination(pagination),
          })),
      {
        ...config,
        enabled: Boolean(projectKey) && (config.enabled ?? true),
      }
    );
  },
});
