import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useCurrentProjectKey } from 'context/current-project';
import {
  deserializeJackpot,
  deserializeJackpotTeam,
  deserializePagination,
} from 'utils/deserialize';
import { createPaginatedDataHook, createQueryKeys } from 'utils/query';
import { serializeJackpotTeam } from 'utils/serialize';

import { apiClient } from './client-sportsbook-api';

const jackpotsHitsQueriesKeys = createQueryKeys('jackpotsHits');
const jackpotTeamsQueriesKeys = createQueryKeys('jackpotTeams');

export const useJackpotHits = createPaginatedDataHook({
  useData: ({ params = {}, config = {} }) => {
    const { page = 1, limit = 25, ...restParams } = params;
    return useQuery(
      jackpotsHitsQueriesKeys.list({ ...params }),
      () =>
        apiClient.axios
          .get('/jackpots/hits', {
            params: {
              page,
              limit,
              ...restParams,
            },
          })
          .then(response => response.data)
          .then(({ data, pagination }) => ({
            data: data.map(deserializeJackpot),
            pagination: deserializePagination(pagination),
          })),
      {
        ...config,
        enabled: config.enabled ?? true,
      }
    );
  },
});

export const useHandleJackpotHit = ({ isApproved, hitId, config = {} }) => {
  const queryClient = useQueryClient();

  return useMutation(
    () => apiClient.axios.post('/jackpots/hits', { approved: isApproved, hit_id: hitId }),
    {
      ...config,
      onSuccess: () => {
        config.onSuccess?.();

        return queryClient.invalidateQueries(jackpotsHitsQueriesKeys.lists());
      },
    }
  );
};

export const useJackpotTeams = createPaginatedDataHook({
  useData: ({ params = {}, config = {} }) => {
    const projectKey = useCurrentProjectKey();
    const { page = 1, limit = 25 } = params;

    return useQuery(
      jackpotTeamsQueriesKeys.list({ projectKey, ...params }),
      () =>
        apiClient.axios
          .get(`/jackpots/teams`, {
            params: {
              page,
              limit,
            },
            headers: {
              'X-Project': projectKey,
            },
          })
          .then(response => response.data)
          .then(({ data, pagination }) => ({
            data: data.map(deserializeJackpotTeam),
            pagination: deserializePagination(pagination),
          })),
      {
        ...config,
        enabled: Boolean(projectKey) && (config.enabled ?? true),
      }
    );
  },
});

export function useAddJackpotTeam(config = {}) {
  const queryClient = useQueryClient();
  const projectKey = useCurrentProjectKey();

  return useMutation(
    data =>
      apiClient.axios.post(`/jackpots/teams`, serializeJackpotTeam(data), {
        headers: {
          'X-Project': projectKey,
        },
      }),
    {
      ...config,
      onSuccess: () => {
        config.onSuccess?.();

        return queryClient.invalidateQueries(jackpotTeamsQueriesKeys.lists());
      },
    }
  );
}
