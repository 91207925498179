import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useTimezone } from 'hooks/use-timezone';
import { SORT_BY } from 'utils/constants';
import {
  deserializeAuditLog,
  deserializeAuditLogObjectStates,
  deserializePagination,
} from 'utils/deserialize';
import { createQueryKeys, createPaginatedDataHook } from 'utils/query';
import { serializeDateFrom, serializeDateTo } from 'utils/serialize';

import { apiClient } from './client-sportsbook-api';
import { useDictionary } from './dictionary';

const queryKeys = createQueryKeys('audit-logs');

export const useAuditLogs = createPaginatedDataHook({
  defaultParams: { sortBy: [SORT_BY.ACTION_AT.DESC] },
  useData: ({ params, config = {} }) => {
    const {
      email,
      projectKey,
      term,
      objects,
      actions,
      period,
      sortBy,
      page = 1,
      limit = 25,
    } = params;
    const timezone = useTimezone();

    return useQuery(
      queryKeys.list(params),
      () =>
        apiClient
          .makeRequest('/audit', {
            params: {
              email: email || undefined,
              project: projectKey || undefined,
              object: objects,
              action: actions,
              term,
              from: serializeDateFrom(period?.[0], timezone),
              to: serializeDateTo(period?.[1], timezone),
              sort_by: sortBy,
              page,
              limit,
            },
          })
          .then(({ data, pagination }) => ({
            data: data.map(deserializeAuditLog),
            pagination: deserializePagination(pagination),
          })),
      config
    );
  },
});

export function useAuditObjectStates(id) {
  return useQuery(queryKeys.path(id, 'object-states'), () =>
    apiClient.makeRequest(`/audit/${id}/object-states`).then(deserializeAuditLogObjectStates)
  );
}

export function useAuditObjects() {
  return useDictionary({
    dictionaryKey: 'audit',
    config: {
      select: Object.keys,
    },
  });
}

export function useAuditActions({ objects = [] }) {
  const selectActionNames = useCallback(
    data =>
      [
        ...new Set(
          Object.entries(data).flatMap(([object, actions]) =>
            objects.includes(object) ? actions : []
          )
        ),
      ].sort(new Intl.Collator('en').compare),
    [objects]
  );

  return useDictionary({
    dictionaryKey: 'audit',
    config: {
      select: selectActionNames,
    },
  });
}
