import { useQuery } from '@tanstack/react-query';

import { createQueryKeys } from 'utils/query';

import { apiClient } from './client-sportsbook-api';

export const sectionQueriesKeys = createQueryKeys('sections');

export function useSections(config = {}) {
  return useQuery(sectionQueriesKeys.all, () => apiClient.getSections(), {
    initialData: [],
    ...config,
  });
}

export function useHasPermission(sectionKey, permission) {
  const { data: hasPermission } = useSections({
    select: sections =>
      sections.find(section => section.key === sectionKey)?.permissions?.includes(permission),
  });

  return hasPermission;
}
