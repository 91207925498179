import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { useCurrentProjectKey } from 'context/current-project';
import { createQueryKeys } from 'utils/query';

import { apiClient } from './client-sportsbook-api';

const termsQueriesKeys = createQueryKeys('terms');

export const useBonusTermsByLanguage = ({ language, bonusType }, config = {}) => {
  const projectKey = useCurrentProjectKey();

  return useQuery(
    ['terms', { language, bonusType }],
    () => apiClient.getTerms({ language, bonusType, projectKey }),
    {
      ...config,
      enabled:
        Boolean(projectKey) && (config.enabled ?? true) && Boolean(language) && Boolean(bonusType),
    }
  );
};

export const useUpdateTerms = ({ bonusTypeName, language, terms, config = {} }) => {
  const queryClient = useQueryClient();
  const projectKey = useCurrentProjectKey();

  return useMutation(() => apiClient.updateTerms({ projectKey, bonusTypeName, language, terms }), {
    ...config,
    onSuccess: () => {
      config.onSuccess?.();

      return queryClient.invalidateQueries(termsQueriesKeys.lists());
    },
  });
};
