import { useProjects } from 'api/project';

export function useAvailableProjectKeys(paramsProjectKeys) {
  const projectsQuery = useProjects({
    initialParams: { limit: 100 },
  });

  const apiProjectKeys = (projectsQuery.data ?? []).map(project => project.key);

  const projectKeys =
    !paramsProjectKeys || paramsProjectKeys?.length === 0
      ? apiProjectKeys
      : paramsProjectKeys.filter(projectKey => apiProjectKeys.includes(projectKey));

  return { projectKeys, ...projectsQuery };
}
