import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { useCurrentProjectKey } from 'context/current-project';
import { useTimezone } from 'hooks/use-timezone';
import {
  deserializeCcfHistoryItem,
  deserializeLtdHistoryItem,
  deserializePagination,
  deserializeSportsbookBet,
  deserializePlayerBonus,
  deserializePlayerStatistics,
} from 'utils/deserialize';
import { createPaginatedDataHook, createQueryKeys, invalidateProfiles } from 'utils/query';
import {
  serializeDateFrom,
  serializeDateTo,
  serializeCcf,
  serializeLtd,
  serializeBetsStatisticsParams,
} from 'utils/serialize';

import { MAP_PLAYER_BONUS_TYPE_TO_API_KEY, apiClient } from './client';

export const playerQueriesKeys = createQueryKeys('players');

export const usePlayers = createPaginatedDataHook({
  useData: ({ params = {}, config = {} }) =>
    useQuery(playerQueriesKeys.list(params), () => apiClient.getPlayers(params), config),
});

export const usePlayer = ({ playerId, projectKey }, config = {}) =>
  useQuery(
    playerQueriesKeys.detail(playerId),
    () => apiClient.getPlayer({ id: playerId, projectKey }),
    config
  );

export const usePlayerMetrics = ({ params = {}, config = {} }) => {
  const projectKey = useCurrentProjectKey();
  const timezone = useTimezone();

  return useQuery(
    playerQueriesKeys.path(projectKey, 'metrics', params),
    () =>
      apiClient
        .makeRequest(`/players/metrics`, {
          baseURL: apiClient.baseUrlApiV2,
          method: 'POST',
          headers: {
            'X-Project': projectKey,
          },
          data: serializeBetsStatisticsParams({ params, timezone }),
        })
        .then(data => deserializePlayerStatistics(data.total)),
    {
      ...config,
      enabled: Boolean(projectKey) && (config.enabled ?? true),
    }
  );
};

export const usePlayerBonuses = createPaginatedDataHook({
  useData: ({ params = {}, config = {} }) => {
    const projectKey = useCurrentProjectKey();
    const timezone = useTimezone();
    const { playerId, ...restParams } = params;
    const { bonusType, issuedPeriod, status, page, limit, term, sortBy } = restParams;

    return useQuery(
      playerQueriesKeys.path(playerId, 'bonuses', { projectKey, ...restParams }),
      () =>
        apiClient
          .makeRequest(`/players/${playerId}/${MAP_PLAYER_BONUS_TYPE_TO_API_KEY[bonusType]}`, {
            params: {
              issued_from: serializeDateFrom(issuedPeriod?.[0], timezone),
              issued_to: serializeDateTo(issuedPeriod?.[1], timezone),
              limit,
              page,
              status,
              term,
              sort_by: sortBy,
            },
            headers: {
              'X-Project': projectKey,
            },
          })
          .then(({ data, pagination }) => ({
            data: data.map(bonus => deserializePlayerBonus(bonusType, bonus)),
            pagination: deserializePagination(pagination),
          })),
      {
        ...config,
        enabled: Boolean(projectKey) && (config.enabled ?? true),
      }
    );
  },
});

export const usePlayerBets = createPaginatedDataHook({
  useData: ({ params = {}, config = {} }) => {
    const projectKey = useCurrentProjectKey();

    const { playerId, limit, sortBy } = params;

    return useQuery(
      playerQueriesKeys.path(playerId, 'bets', { projectKey, limit, sortBy }),
      () =>
        apiClient
          .makeRequest(`/players/${playerId}/bets`, {
            params: {
              limit,
              sort_by: sortBy,
            },
            headers: { 'X-Project': projectKey },
          })
          .then(({ data }) => ({ data: data.map(deserializeSportsbookBet) })),
      {
        ...config,
        enabled: Boolean(projectKey) && (config.enabled ?? true),
      }
    );
  },
});

export const usePlayerEditCcf = ({ params = {}, config } = {}) => {
  const queryClient = useQueryClient();

  const { profiles, projectKey } = params;

  return useMutation(
    payload =>
      apiClient.makeRequest('/players/ccf', {
        baseURL: apiClient.baseUrlApiV2,
        method: 'PATCH',
        headers: {
          'X-Project': projectKey,
        },
        data: { ccfs: serializeCcf({ payload, profiles }) },
      }),
    {
      ...config,
      onSuccess: () => {
        config.onSuccess?.();

        return invalidateProfiles({
          historyPath: 'ccf-history',
          keys: playerQueriesKeys,
          profiles,
          queryClient,
        });
      },
    }
  );
};

export const usePlayerEditLtd = ({ params = {}, config } = {}) => {
  const queryClient = useQueryClient();

  const { profiles, projectKey } = params;

  return useMutation(
    payload =>
      apiClient.makeRequest('/players/ltd', {
        baseURL: apiClient.baseUrlApiV2,
        method: 'PATCH',
        headers: {
          'X-Project': projectKey,
        },
        data: { ltds: serializeLtd({ payload, profiles }) },
      }),
    {
      ...config,
      onSuccess: () => {
        config.onSuccess?.();

        return invalidateProfiles({
          historyPath: 'ltd-history',
          keys: playerQueriesKeys,
          profiles,
          queryClient,
        });
      },
    }
  );
};

export const usePlayerCcfHistory = ({ params = {}, config = {} } = {}) =>
  useQuery(
    playerQueriesKeys.path('ccf-history', params),
    () =>
      apiClient
        .makeRequest('/players/ccf/history', {
          params: { global_id: params.globalId },
          headers: { 'X-Project': params.projectKey },
        })
        .then(response => response.data.map(deserializeCcfHistoryItem)),
    {
      ...config,
      enabled: Boolean(params.projectKey) && (config.enabled ?? true),
    }
  );

export const usePlayerLtdHistory = ({ params = {}, config = {} } = {}) =>
  useQuery(
    playerQueriesKeys.path('ltd-history', params),
    () =>
      apiClient
        .makeRequest('/players/ltd/history', {
          params: { global_id: params.globalId },
          headers: { 'X-Project': params.projectKey },
        })
        .then(response => response.data.map(deserializeLtdHistoryItem)),
    {
      ...config,
      enabled: Boolean(params.projectKey) && (config.enabled ?? true),
    }
  );
