import dayjs from 'dayjs';

import { useUserProfile } from 'context/auth';

export function useTimezone() {
  const { userProfile } = useUserProfile();

  return userProfile?.tz ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function useTimezoneOffsetInMinutes() {
  const tz = useTimezone();

  return dayjs().tz(tz).utcOffset();
}
